/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, Script } from "gatsby"
import { useLocation } from "@reach/router"

function SEO({ description, lang, meta, title, noindex, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )



  const metaDescription = description || site.siteMetadata.description
  var  defaultTitle = site.siteMetadata?.title

  const { pathname } = useLocation()
  var curentUrl = '';
  curentUrl = process.env.GATSBY_SITE_URL+pathname;
  if (!curentUrl.endsWith('/'))
      curentUrl = curentUrl + '/';

  //remove multiple slashes
  curentUrl = curentUrl.replace(/([^:]\/)\/+/g, "$1");
  //set lowercase url
  curentUrl = curentUrl.toLowerCase();

  if (pathname.includes('/interior-design-in-qatar/')) {
    //title = "Interior Design Qatar | Home Refurbishment | The Pearl Gates Decor";
    defaultTitle = null
  }
  if (pathname.includes('/interior-design-in-qatar/one-bedroom-apartment-in-the-pearl-island/')) {
    title = "One bedroom apartment In The Pearl Island | The Pearl Gates Decor";
    defaultTitle = null
  }
  if (pathname.includes('/interior-design-in-qatar/studio-in-the-pearl-island/')) {
    title = "Studio in The Pearl Island | The Pearl Gates Decor";
    defaultTitle = null
  }
  if (pathname.includes('/interior-design-in-qatar/two-bedroom-apartment-in-the-pearl-island/')) {
    title = "Two bedroom apartment In The Pearl Island | The Pearl Gates Decor";
    defaultTitle = null
  }
  if (pathname.includes('/interior-design-in-qatar/4-bedroom-townhouse-at-the-pearl-island/')) {
    title = "4-bedroom townhouse at The Pearl Island | The Pearl Gates Decor";
    defaultTitle = null
  }
  
  let metaItems = [
    {
      name: `description`,
      content: metaDescription,
    },

        {
          property: `og:title`,
          name: `title`,
          content: title,
        },
        {
          property: `og:description`,
          //name:`description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: curentUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `facebook-domain-verification`,
          content: `hy5ad1vexqk1ttcihc960qs3qg4nh8`,
        },
      ].concat(meta);

  if ( noindex ) {
     metaItems.push({
       name: `robots`,
       content: `noindex`,
     })
  }

  if ( image ) {
     metaItems.push(
      {
       property: `og:image`,
       name:`image`,
       content: image,
     },
     {     
      name: `twitter:image`,
      content: image,
    },
    )
  }

  return (
    <>
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
          {
              href: curentUrl,
              rel: "canonical"
          }
      ]}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={metaItems}
    />
    {pathname.includes('/interior-design-in-qatar/') &&
      <>
        <Helmet>
          <script>
            {`
            !function(e,t,n){e.TiktokAnalyticsObject=n;var o=e[n]=e[n]||[];o.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],o.setAndDefer=function(e,t){e[t]=function(){e.push([t].concat(Array.prototype.slice.call(arguments,0)))}};for(var a=0;a<o.methods.length;a++)o.setAndDefer(o,o.methods[a]);o.instance=function(e){for(var t=o._i[e]||[],n=0;n<o.methods.length;n++)o.setAndDefer(t,o.methods[n]);return t},o.load=function(e,t){var a='analytics.tiktok.com/i18n/pixel/events.js';t&&t.partner,o._i=o._i||{},o._i[e]=[],o._i[e]._u=a,o._t=o._t||{},o._t[e]=+new Date,o._o=o._o||{},o._o[e]=t||{},(t=document.createElement("script")).type="text/javascript",t.async=!0,t.src=a+"?sdkid="+e+"&lib="+n,(e=document.getElementsByTagName("script")[0]).parentNode.insertBefore(t,e)},o.load("CVBTNA3C77U61UGLMPIG")}(window,document,"ttq");
            `}
          </script>
        </Helmet>
        <noscript>
          <a href="ttq.page" rel="noreferrer" target="_blank">ttq.page</a>
        </noscript>
      </>
  }
    </>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  noindex: false,
  image: null,
}

export default SEO
